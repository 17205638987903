import Typography from "../../models/typography";
import platformNames from "../platformNames";
import { APP_ELEMENTS_AND_CLASS } from "./constants";
import defaultAppFont from "./typography/default";
import jackAndJonesJrFont from "./typography/jack-and-jones-jr";
import swiggyInstamartFont from "./typography/swiggy";
import veromodaGirlFont from "./typography/veromoda-girl";

const supportedBrand = {
  [platformNames.SWIGGY_REG.value]: swiggyInstamartFont,
  [platformNames.SWIGGY.value]: swiggyInstamartFont,
  [platformNames.JackAndJonesJR.value]: jackAndJonesJrFont,
  [platformNames.VeromodaGirl.value]: veromodaGirlFont,
};

const getFontFormatFromExtension = (extension: string) => {
  switch (extension) {
    case "woff":
      return "woff";
    case "woff2":
      return "woff2";
    case "ttf":
      return "truetype";
    case "otf":
      return "opentype";
    default:
      return "woff";
  }
};

const setupFontLinkToApplication = (typography: Typography) => {
  // there will be three types of font link
  // 1. google font link
  // 2. local font link
  // 3. other website font link like direct link to font file

  // parse link to check type of link use regex to check if it is font file or google font link

  const link = typography.src;
  const fontFamily = typography.fontFamily;
  const fontWeight = typography.fontWeight;

  const googleFontRegex = /https:\/\/fonts.googleapis.com\/css2\?family=(.*)/;
  const localFontRegex = /src\/assets\/fonts\/(.*)/;
  const fontFileRegex = /(.*)\.(woff|woff2|ttf|otf)/;

  if (googleFontRegex.test(link)) {
    const googleFontLink = document.createElement("link");
    googleFontLink.href = link;
    googleFontLink.rel = "stylesheet";
    document.head.appendChild(googleFontLink);
  } else if (fontFileRegex.test(link)) {
    // load font file

    const fontFace = document.createElement("style");
    let fontSrc = link;

    const fontFileExtension = link.split(".").pop();
    const fontFormat = getFontFormatFromExtension(fontFileExtension || "");

    if (localFontRegex.test(link)) {
      fontSrc = `${link}`;
    }

    fontFace.innerHTML = `
      @font-face {
        font-family: '${fontFamily}';
        src: url('${fontSrc}') format('${fontFormat}');
        font-weight: ${fontWeight};
      }
    `;
    document.head.appendChild(fontFace);
  }
};

const setFontFromTypographyConfiguration = (
  typographyConfiguration: Typography[],
) => {
  const style = document.createElement("style");

  typographyConfiguration.forEach((typography) => {
    if (typography.src) {
      setupFontLinkToApplication(typography);
    }
  });

  style.innerHTML = typographyConfiguration
    .map((typography) => {
      if (typography.element === APP_ELEMENTS_AND_CLASS.BODY.value) {
        return `
        body * {
          font-family: ${typography.fontFamily};
          font-weight: ${typography.fontWeight};
          font-style: ${typography.style};
        }
      `;
      } else if (typography.element === APP_ELEMENTS_AND_CLASS.CUSTOM_FONT.value) {
        return `
        .custom-font {
          font-family: ${typography.fontFamily};
          font-weight: ${typography.fontWeight};
          font-style: ${typography.style};
        }
          
        .custom-font-medium {
          font-family: ${typography.fontFamily};
          font-weight: 500;
          font-style: ${typography.style};
        }
      `;
      } else {
        return `
        ${typography.isCustomTag ? "." : ""}${typography.element} {
          font-family: ${typography.fontFamily};
          font-weight: ${typography.fontWeight};
          font-style: ${typography.style};
        }
      `;
      }
    })
    .join("");

  document.head.appendChild(style);

  return () => document.head.removeChild(style);
};

const setFontInApplication = (partnerDomain: string) => {
  const brandFontConfiguration = supportedBrand[partnerDomain];
  if (brandFontConfiguration) {
    // Set font in application according to brandFontConfiguration
    setFontFromTypographyConfiguration(brandFontConfiguration);
  } else {
    setFontFromTypographyConfiguration(defaultAppFont);
  }
};

export default setFontInApplication;
