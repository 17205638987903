import Typography from "../../../models/typography";
import { APP_ELEMENTS_AND_CLASS, FONT_STYLES } from "../constants";

const jackAndJonesJrFont: Typography[] = [
  {
    element: APP_ELEMENTS_AND_CLASS.BODY.value,
    src: "/assets/fonts/futura/thin.otf",
    fontFamily: "futura-thin",
    fontWeight: 'normal',
    style: FONT_STYLES.NORMAL,
  },
  {
    element: APP_ELEMENTS_AND_CLASS.HEADLINE.value,
    src: "/assets/fonts/futura/bold.otf",
    fontFamily: "futura-bold",
    fontWeight: '800',
    style: FONT_STYLES.NORMAL,
    isCustomTag: true
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H1.value,
    src: "/assets/fonts/futura/heavy.otf",
    fontFamily: "futura-semi-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H2.value,
    src: "/assets/fonts/futura/heavy.otf",
    fontFamily: "futura-semi-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H3.value,
    src: "/assets/fonts/futura/heavy.otf",
    fontFamily: "futura-semi-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H4.value,
    src: "/assets/fonts/futura/medium.otf",
    fontFamily: "futura-medium",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H5.value,
    src: "/assets/fonts/futura/medium.otf",
    fontFamily: "futura-medium",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H6.value,
    src: "/assets/fonts/futura/medium.otf",
    fontFamily: "futura-medium",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.CUSTOM_FONT.value,
    src: "/assets/fonts/futura/normal.otf",
    fontFamily: "futura-regular",
    fontWeight: 'normal',
    style: FONT_STYLES.NORMAL,
    isCustomTag: true
  }
]


export default jackAndJonesJrFont;